import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  SignUp,
  GetBank,
  ValidateBank,
  setNotifyMessage,
  setIsVerifyingBank,
  setBankAccount,
} from "../../Features/kitchenSlice";
import { Select, Modal, notification } from "antd";
import { useNavigate } from "react-router-dom";
import {
  selectKitchen,
  useAppSelector,
  useAppDispatch,
} from "../../Store/store";

function Signup() {
  const dispatch = useAppDispatch();
  const {
    banks,
    isVerifyingBank,
    isBankVerified,
    userData,
    notifyMessage,
    bankAccount,
  } = useAppSelector(selectKitchen);

  const [activeTab, setActiveTab] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    KitchenName: "",
    KitchenEmail: "",
    ManagerFirstName: "",
    ManagerLastName: "",
    ManagerPhone: "",
    ManagerEmail: "",
    Password: "",
    University: "",
    AccountNumber: "",
    AccountName: "",
    BankCode: "",
    BankName: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      navigate("/home");
    }
  }, [userData, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTabChange = (tabNumber) => {
    if (tabNumber > activeTab) {
      if (isFormValid(activeTab)) {
        setActiveTab(tabNumber);
      }
    } else {
      setActiveTab(tabNumber);
    }
  };

  const handleVerifyBankDetails = async () => {
    if (!isVerifyingBank) {
      dispatch(setIsVerifyingBank(true));
      const payload = { ...formData, ShouldProceed: false };
      dispatch(ValidateBank(payload));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(SignUp(formData));
  };

  const isFormValid = (tab) => {
    switch (tab) {
      case 1:
        return (
          formData.KitchenName && formData.KitchenEmail && formData.University
        );
      case 2:
        return (
          formData.ManagerFirstName &&
          formData.ManagerLastName &&
          formData.ManagerEmail &&
          formData.ManagerPhone &&
          formData.Password
        );
      case 3:
        return formData.AccountNumber && formData.BankName;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (bankAccount && !isVerifyingBank) {
      setFormData({ ...formData, AccountName: bankAccount?.account_name });
      setIsModalVisible(true);
    }
  }, [bankAccount, isVerifyingBank]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      if (notifyMessage?.isSuccess === true) {
        var response = { ...notifyMessage };
        delete response.isSuccess;
        if (
          response?.description !==
          "Your bank account has been verified successfully."
        ) {
          navigate("/verifyEmail");
        }
        response = { ...response };
        notification.success(response);
        dispatch(setNotifyMessage(null));
      } else if (notifyMessage?.isSuccess === false && notifyMessage?.message) {
        response = { ...notifyMessage };
        delete response.isSuccess;
        response = { ...response };
        notification.error(response);
        dispatch(setNotifyMessage(null));
      }
    }
  }, [navigate, dispatch, notifyMessage, formData]);

  useEffect(() => {
    if (!banks) dispatch(GetBank());
  }, [banks, dispatch]);

  const handleConfirmation = async () => {
    setIsModalVisible(false);
    const payload = {
      ...formData,
      ShouldProceed: true,
    };
    // console.log("ShouldProceedTrue ", payload);
    dispatch(ValidateBank(payload));
    dispatch(setBankAccount(null));
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center px-4 py-6">
      <div className="w-full max-w-3xl p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold font-roboto text-orange-700">
            QuicKee
          </h1>
          <Link to="/signIn" className="text-sm text-blue-500 hover:underline">
            Sign In
          </Link>
        </div>
        <div className="flex justify-between mb-4">
          <button
            className={`w-1/3 py-2 border-b-2 ${
              activeTab === 1
                ? "border-orange-700 text-orange-700"
                : "border-gray-200 text-gray-500"
            }`}
            onClick={() => handleTabChange(1)}
          >
            Kitchen Registration
          </button>
          <button
            className={`w-1/3 py-2 border-b-2 ${
              activeTab === 2
                ? "border-orange-700 text-orange-700"
                : "border-gray-200 text-gray-500"
            }`}
            onClick={() => handleTabChange(2)}
          >
            Manager Registration
          </button>
          <button
            className={`w-1/3 py-2 border-b-2 ${
              activeTab === 3
                ? "border-orange-700 text-orange-700"
                : "border-gray-200 text-gray-500"
            }`}
            onClick={() => handleTabChange(3)}
          >
            Bank Details
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          {activeTab === 1 && (
            <div>
              <div>
                <input
                  type="text"
                  name="KitchenName"
                  placeholder="Kitchen Name"
                  value={formData.KitchenName}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg p-2"
                  style={{ width: "100%" }}
                />
                <input
                  type="email"
                  name="KitchenEmail"
                  placeholder="Kitchen Email"
                  value={formData.KitchenEmail}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg p-2"
                  style={{ width: "100%" }}
                />
                <input
                  type="text"
                  name="University"
                  placeholder="Kitchen Location"
                  value={formData.University}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg p-2"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div>
              {/* <h2 className="text-lg font-medium mb-4">Manager Registration</h2> */}
              <div>
                <input
                  type="text"
                  name="ManagerFirstName"
                  placeholder="First Name"
                  value={formData.ManagerFirstName}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg p-2"
                  style={{ width: "100%" }}
                />
                <input
                  type="text"
                  name="ManagerLastName"
                  placeholder="Last Name"
                  value={formData.ManagerLastName}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg p-2"
                  style={{ width: "100%" }}
                />
                <input
                  type="email"
                  name="ManagerEmail"
                  placeholder="Email"
                  value={formData.ManagerEmail}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg p-2"
                  style={{ width: "100%" }}
                />
                <input
                  type="text"
                  name="ManagerPhone"
                  placeholder="Phone Number"
                  value={formData.ManagerPhone}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg p-2"
                  style={{ width: "100%" }}
                />
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="Password"
                    placeholder="Password"
                    value={formData.Password}
                    onChange={handleInputChange}
                    className="w-full border rounded-lg p-2"
                    style={{ width: "100%" }}
                  />
                  <button
                    type="button"
                    className="absolute top-1/3 right-3 transform -translate-y-1/2 text-sm text-blue-500"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeTab === 3 && (
            <div>
              {/* Bank Name Select */}
              <div className="mb-3">
                <label
                  htmlFor="BankName"
                  className="block text-gray-600 font-medium"
                >
                  Kitchen Bank Name
                </label>
                <Select
                  className="w-full"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value, option) => {
                    const selectedBank = banks.find(
                      (bank) => bank.name === value
                    );
                    if (selectedBank) {
                      setFormData({
                        ...formData,
                        BankName: value,
                        BankCode: selectedBank.code,
                      });
                    }
                  }}
                >
                  {banks?.map((bank) => (
                    <Select.Option key={bank.id} value={bank.name}>
                      {bank.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              {/* Account Number Input */}
              <div>
                <input
                  type="number"
                  id="AccountNumber"
                  name="AccountNumber"
                  placeholder="Input your Account Number"
                  value={formData.AccountNumber}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                  style={{ width: "100%" }}
                  required
                />
              </div>

              {/* Verify Button */}
              <button
                type="button"
                onClick={handleVerifyBankDetails}
                className={`w-full py-2 px-4 text-white rounded-md mb-3 ${
                  isVerifyingBank || !isFormValid(activeTab)
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-orange-700 hover:bg-orange-700"
                }`}
                disabled={isVerifyingBank || !isFormValid(activeTab)}
              >
                {isVerifyingBank
                  ? "Verifying Bank Details..."
                  : "Verify Bank Details"}
              </button>

              {/* Modal */}
              <Modal
                title="Bank Account Verification"
                open={isModalVisible}
                onOk={handleConfirmation}
                onCancel={() => {
                  setIsModalVisible(false);
                  dispatch(setBankAccount(null)); // Clear the bank account cache
                }}
              >
                <p className="text-gray-600 text-sm">
                  Please confirm that "{formData?.AccountName}" is the name
                  associated with your bank account.
                </p>
              </Modal>
            </div>
          )}
          <div className="flex justify-between">
            {activeTab > 1 && (
              <button
                type="button"
                className="px-4 py-2 bg-gray-300 rounded-lg"
                onClick={() => handleTabChange(activeTab - 1)}
              >
                Previous
              </button>
            )}
            {activeTab < 3 && (
              <button
                type="button"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                onClick={() => handleTabChange(activeTab + 1)}
              >
                Next
              </button>
            )}
            {activeTab === 3 && (
              <button
                type="submit"
                className="px-4 py-2 bg-green-500 text-white rounded-lg"
                disabled={!isBankVerified}
                style={{ backgroundColor: isBankVerified ? "green" : "grey" }}
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";
import { ResetPasswords, setNotifyMessage } from "../../Features/kitchenSlice";
import {
  selectKitchen,
  useAppSelector,
  useAppDispatch,
} from "../../Store/store";

function ResetPassword() {
  const { notifyMessage } = useAppSelector(selectKitchen);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { email } = useParams();
  const [formData, setFormData] = useState({
    Email: email,
    OTP: "",
    NewPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (notifyMessage?.isSuccess) {
      notification.success({ message: notifyMessage.message });
      dispatch(setNotifyMessage(null));
      navigate("/signIn");
    } else if (!notifyMessage?.isSuccess && notifyMessage?.message) {
      notification.error({ message: notifyMessage.message });
      dispatch(setNotifyMessage(null));
      if (notifyMessage?.message === "Expired OTP") navigate("/forgotPassword");
    }
  }, [dispatch, navigate, notifyMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      Email: formData.Email,
      OTP: formData.OTP,
      NewPassword: formData.NewPassword,
    };
    dispatch(ResetPasswords(payload));
  };

  const handleSignInBack = () => navigate("/signIn");

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-100 to-gray-300">
      <div className="w-full max-w-md p-6 md:p-8">
        <h2 className="text-center text-2xl font-semibold text-orange-700 mb-6">
          Reset Password
        </h2>

        <form onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="Email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              name="Email"
              value={formData.Email}
              onChange={handleInputChange}
              placeholder="Enter your email"
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
              style={{ textTransform: "lowercase", width: "100%" }}
              required
            />
          </div>

          <div>
            <label
              htmlFor="OTP"
              className="block text-sm font-medium text-gray-700"
            >
              OTP
            </label>
            <input
              type="text"
              name="OTP"
              value={formData.OTP}
              onChange={handleInputChange}
              placeholder="Enter OTP"
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
              style={{ width: "100%" }}
              required
            />
          </div>

          <div className="relative">
            <label
              htmlFor="NewPassword"
              className="block text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              name="NewPassword"
              value={formData.NewPassword}
              onChange={handleInputChange}
              placeholder="Enter your new password"
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
              style={{ width: "100%" }}
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute top-1/2 right-4 transform -translate-y-1/3 text-sm text-gray-600 hover:text-orange-600"
            >
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>

          <button
            type="submit"
            className="w-full py-2 bg-orange-600 text-white rounded-md hover:bg-orange-500 transition"
            style={{ width: "100%", backgroundColor: "#c45628" }}
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;

import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Store/store";
import {
  AdminGetUsers,
  AdminSearchUsers,
  AdminGetAllReferral,
  AdminGetReferralByMail,
} from "../../../../Features/kitchenSlice";
import { format, set } from "date-fns";

const UserDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchEmail, setSearchEmail] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState(null); // Local state for search results
  const [referralResults, setReferralResults] = useState(null);
  const [orderResults, setOrdersResults] = useState(null);
  const [loading, setLoading] = useState(false); // Local state for loading

  const dispatch = useAppDispatch();
  const { getUsers } = useAppSelector((state) => state.kitchen);

  const totalPages = getUsers?.pagination?.totalPages || 1;

  useEffect(() => {
    if (!isSearching) {
      dispatch(AdminGetUsers(currentPage));
    }
  }, [currentPage, isSearching, dispatch]);

  const handleSearch = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(searchEmail.trim())) {
      setIsSearching(true);
      setLoading(true);
      try {
        const result = await AdminSearchUsers(searchEmail.trim());
        setSearchResults(result);
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please enter a valid email address.");
    }
  };

  const handleViewOrders = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(searchEmail.trim())) {
      setIsSearching(true);
      setLoading(true);
      try {
        const result = await AdminGetReferralByMail(
          searchEmail.trim(),
          currentPage
        );
        setOrdersResults(result);
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please enter a valid email address.");
    }
  };

  const referralCodeCounts = orderResults?.reduce((acc, order) => {
    const code = order.ReferralCode;
    acc[code] = (acc[code] || 0) + 1;
    return acc;
  }, {});

  const handleViewReferrals = async () => {
    if (searchEmail.trim()) {
      try {
        const referral = await AdminGetAllReferral(searchEmail, currentPage);
        setReferralResults(referral);
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const clearSearch = () => {
    setSearchEmail("");
    setIsSearching(false);
    setSearchResults(null); // Clear the local search results
    setOrdersResults(null);
    dispatch(AdminGetUsers(currentPage));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const formatCreatedAt = (isoString) => {
    return format(new Date(isoString), "PPP p");
  };

  // console.log("Order: ", orderResults);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4 text-center sm:text-left">
        User Details
      </h1>

      {/* Search Bar */}
      <div className="flex flex-col sm:flex-row items-center justify-between mb-4 gap-2">
        <input
          type="text"
          placeholder="Search by email"
          className="border rounded w-full sm:w-1/2 py-2 px-4 text-sm"
          value={searchEmail}
          onChange={(e) => setSearchEmail(e.target.value)}
        />
        <div className="flex gap-2">
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded"
            onClick={handleSearch}
          >
            Search
          </button>
          {isSearching && (
            <button
              className="bg-red-500 text-white py-2 px-4 rounded"
              onClick={clearSearch}
            >
              Clear
            </button>
          )}
        </div>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {!isSearching && (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Name
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Email
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      University
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Telephone
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Referral Code
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Referrals
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Referred By
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Joined
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getUsers?.data.map((user) => (
                    <tr key={user.Id}>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {user.FirstName} {user.LastName}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {user.Email}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {user.University}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {user.PhoneNumber}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {user.ReferralCode}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {user.Referrals}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {user.ReferralBy}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {formatCreatedAt(user.CreatedAt)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {isSearching && (
            <div className="overflow-x-auto mt-6">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Name
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Email
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      University
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Telephone
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Referral Code
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Referrals
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Referred By
                    </th>
                    <th className="py-2 px-4 border-b text-sm sm:text-base">
                      Joined
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults ? (
                    <tr key={searchResults.Id}>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {searchResults.FirstName} {searchResults.LastName}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {searchResults.Email}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {searchResults.University}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {searchResults.PhoneNumber}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {searchResults.ReferralCode}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {searchResults.Referrals}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {searchResults.ReferralBy}
                      </td>
                      <td className="py-2 px-4 border-b text-sm sm:text-base">
                        {formatCreatedAt(searchResults.CreatedAt)}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td
                        colSpan="8"
                        className="py-2 px-4 text-center text-sm sm:text-base"
                      >
                        No search results found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}

      {referralResults && (
        <div className="mt-6">
          <h2 className="text-xl font-semibold mb-2">Total Orders</h2>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-sm">Referral Code</th>
                <th className="py-2 px-4 border-b text-sm">Total Referrals</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(referralResults.data) &&
                referralResults.data.map((referral) => (
                  <tr key={referral.OrderId}>
                    <td className="py-2 px-4 border-b text-sm">
                      {referral.ReferralCode}
                    </td>
                    <td className="py-2 px-4 border-b text-sm">
                      {referral.length}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {orderResults && (
        <div className="overflow-x-auto mt-6">
          <h2 className="text-xl font-semibold mb-2">
            Total Orders Via ReferralCode
          </h2>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-sm">Referral Code</th>
                <th className="py-2 px-4 border-b text-sm">
                  Total Orders by referrals
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(referralCodeCounts).length > 0 ? (
                Object.entries(referralCodeCounts).map(([code, count]) => (
                  <tr key={code}>
                    <td className="py-2 px-4 border-b text-sm sm:text-base">
                      {code}
                    </td>
                    <td className="py-2 px-4 border-b text-sm sm:text-base">
                      {count}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="2"
                    className="py-2 px-4 text-center text-sm sm:text-base"
                  >
                    No referral codes found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {isSearching && (
        <div className="flex flex-col sm:flex-row justify-between items-center mt-4 space-y-2 sm:space-y-0">
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50 w-full sm:w-auto"
            onClick={handleViewReferrals}
          >
            View Refferals
          </button>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50 w-full sm:w-auto"
            onClick={handleViewOrders}
          >
            Total Orders
          </button>
        </div>
      )}

      {!isSearching && (
        <div className="flex flex-col sm:flex-row justify-between items-center mt-4 space-y-2 sm:space-y-0">
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50 w-full sm:w-auto"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-gray-700 text-sm sm:text-base">
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50 w-full sm:w-auto"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default UserDetails;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  VerifyEmail,
  ResendVerifyEmail,
  setNotifyMessage,
} from "../../../Features/kitchenSlice";
import { notification } from "antd";
import {
  selectKitchen,
  useAppSelector,
  useAppDispatch,
} from "../../../Store/store";

function Verifymail() {
  const dispatch = useAppDispatch();
  const { notifyMessage } = useAppSelector(selectKitchen);
  const [formData, setFormData] = useState({
    Email: "",
    EmailOTP: "",
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showResend = queryParams.get("showResend") === "true";
  const [resendEmail, setResendEmail] = useState(showResend);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (window.location.pathname.includes("/verifyEmail")) {
      if (notifyMessage?.isSuccess === true) {
        var response = { ...notifyMessage };
        delete response.isSuccess;
        if (notifyMessage?.message === "Email Verified Success") {
          navigate("/signIn");
        } else if (notifyMessage?.message === "Email Resent") {
          setResendEmail(false);
        }
        notification.success(response);
        dispatch(setNotifyMessage(null));
      } else if (notifyMessage?.isSuccess === false && notifyMessage?.message) {
        response = { ...notifyMessage };
        delete response.isSuccess;
        notification.error(response);
        dispatch(setNotifyMessage(null));
        if (response?.message === "Expired OTP") {
          setResendEmail(formData.Email);
          navigate(`/verifyEmail?showResend=true&email=${formData.Email}`);
        }
      }
    }
  }, [navigate, dispatch, notifyMessage, formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      Email: formData.Email,
      EmailOTP: formData.EmailOTP,
    };

    dispatch(VerifyEmail(payload));
  };

  const handleResendEmail = async (e) => {
    e.preventDefault();
    dispatch(ResendVerifyEmail(formData.Email));
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 px-4">
      <div className="w-full max-w-md p-6">
        <h1
          className="text-2xl font-bold text-orange-700 text-center cursor-pointer"
          onClick={() => window.location.reload()}
        >
          QuicKee
        </h1>
        <h2 className="text-xl font-semibold text-center mt-4">
          {showResend ? "Resend Verify Email" : "Verify Your Kitchen Email"}
        </h2>
        {resendEmail ? (
          <form onSubmit={handleResendEmail} className="mt-6">
            <div className="mb-4">
              <label
                htmlFor="Email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="Email"
                name="Email"
                placeholder="Enter your email"
                value={formData.Email}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-orange-500 focus:border-orange-500"
                style={{ width: "100%" }}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400"
            >
              Resend Email
            </button>
          </form>
        ) : (
          <form onSubmit={handleSubmit} className="mt-6">
            <div className="mb-2">
              <label
                htmlFor="Email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="Email"
                name="Email"
                placeholder="Enter your email"
                value={formData.Email}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-orange-500 focus:border-orange-500"
                style={{ width: "100%" }}
                required
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="EmailOTP"
                className="block text-sm font-medium text-gray-700"
              >
                OTP
              </label>
              <input
                type="number"
                id="EmailOTP"
                name="EmailOTP"
                placeholder="Enter your OTP"
                value={formData.EmailOTP}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-orange-500 focus:border-orange-500"
                style={{ width: "100%" }}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400"
              style={{ width: "100%", backgroundColor: "#c45628" }}
            >
              Verify
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default Verifymail;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Forgotpassword, setNotifyMessage } from "../../Features/kitchenSlice";
import { notification } from "antd";
import {
  useAppDispatch,
  useAppSelector,
  selectKitchen,
} from "../../Store/store";

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { notifyMessage } = useAppSelector(selectKitchen);

  const [formData, setFormData] = useState({
    Email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (window.location.pathname.includes("/forgotPassword")) {
      if (notifyMessage?.isSuccess === true) {
        var response = { ...notifyMessage };
        delete response.isSuccess;
        response = { ...response };
        notification.success(response);
        dispatch(setNotifyMessage(null));
        navigate(`/resetPassword?${formData.Email}`);
      } else if (notifyMessage?.isSuccess === false && notifyMessage?.message) {
        response = { ...notifyMessage };
        delete response.isSuccess;
        response = { ...response };
        notification.error(response);
        dispatch(setNotifyMessage(null));
      }
    }
  }, [navigate, dispatch, notifyMessage, formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      Email: formData.Email,
    };
    dispatch(Forgotpassword(payload));
  };

  const handleSignInBack = () => {
    navigate("/signIn");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-4">
      <div className="w-full max-w-md p-6">
        <div className="flex justify-between items-center mb-6">
          <h1
            className="text-2xl font-bold text-orange-700 cursor-pointer"
            onClick={() => window.location.reload()}
          >
            QuicKee
          </h1>
          <button
            onClick={handleSignInBack}
            className="text-sm text-orange-700 hover:underline"
          >
            Back to Sign In
          </button>
        </div>
        <h2 className="text-xl font-semibold text-center text-orange-700 mb-4">
          Forgot Password
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="Email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              name="Email"
              placeholder="Enter your email"
              value={formData.Email}
              onChange={handleInputChange}
              style={{ textTransform: "lowercase", width: "100%" }}
              className="w-full mt-2 px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-gray-700"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-medium py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Proceed
          </button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Signin, setNotifyMessage } from "../../Features/kitchenSlice";
import { notification } from "antd";
import {
  selectKitchen,
  useAppSelector,
  useAppDispatch,
} from "../../Store/store";
import { Eye, EyeSlash } from "phosphor-react";

function SignIn() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { notifyMessage, userData } = useAppSelector(selectKitchen);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    Email: "",
    Password: "",
  });

  useEffect(() => {
    if (userData) {
      navigate("/home");
    }
  }, [userData, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (notifyMessage) {
      const response = { ...notifyMessage };
      delete response.isSuccess;

      if (notifyMessage.isSuccess) {
        notification.success(response);
        dispatch(setNotifyMessage(null));
        navigate("/home");
      } else {
        notification.error(response);
        dispatch(setNotifyMessage(null));
        if (response?.message === "Unverified email") {
          navigate(`/verifyEmail?showResend=true&email=${formData.Email}`);
        }
      }
    }
  }, [notifyMessage, dispatch, navigate, formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(Signin(formData));
  };

  return (
    <section className="bg-gray-100 min-h-screen flex items-center justify-center px-4">
      <div className=" w-full max-w-md p-8">
        <h2 className="text-2xl font-bold text-center text-[#c45628]">
          Welcome Back
        </h2>
        <p className="text-sm text-gray-600 text-center mt-2 mb-6">
          Log in to manage your restaurant account.
        </p>

        <form className="flex flex-col" onSubmit={handleSubmit}>
          <div>
            <input
              type="email"
              id="Email"
              name="Email"
              placeholder="Email"
              value={formData.Email}
              onChange={handleInputChange}
              className="p-3 h-12 border rounded-lg focus:ring-2 focus:ring-[#c45628] focus:outline-none"
              style={{ width: "100%" }}
              required
            />
          </div>

          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="Password"
              name="Password"
              placeholder="Password"
              value={formData.Password}
              onChange={handleInputChange}
              className="p-3 h-12 border rounded-lg w-full focus:ring-2 focus:ring-[#c45628] focus:outline-none pr-12"
              style={{ width: "100%" }}
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute top-1/3 right-4 transform -translate-y-1/2 text-gray-500"
            >
              {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
            </button>
          </div>

          <button
            type="submit"
            className="bg-quickee text-white py-3 rounded-lg hover:bg-[#a3441f] transition duration-200"
            style={{ width: "100%", backgroundColor: "#c45628" }}
          >
            Log In
          </button>
        </form>

        <p
          className="text-sm text-center text-[#c45628] mt-4 cursor-pointer hover:underline"
          onClick={() => navigate("/forgotPassword")}
        >
          Forgot your password?
        </p>

        <div>
          <p className="text-gray-600 mt-4 text-sm text-center">
            Don’t have an account?{" "}
            <span
              className="text-[#c45628] cursor-pointer hover:underline"
              onClick={() => navigate("/")}
            >
              Register
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default SignIn;

import React, { useState } from "react";
import "./dash.css";
import { Menu } from "antd";
import {
  AppstoreOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import {
  ChatCircleDots,
  ClockCounterClockwise,
  CookingPot,
  ShoppingCart,
} from "phosphor-react";
import { Modal, Button } from "react-bootstrap";
// import { useMenuContext } from "../MainCode/SideBarLinkPage/Menus/MenuContext";
import { setLogout } from "../Features/kitchenSlice";
import { useNavigate } from "react-router-dom";

import {
  /*selectKitchen, useAppSelector,*/ useAppDispatch,
} from "../Store/store";

function Sidebar({ setSelectedMenuItem }) {
  // const { setUserData, setLogout } = useMenuContext();
  // const { setUserData } = useAppSelector(selectKitchen);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleMenuItemClick = (item) => {
    setSelectedMenuItem(item.key);
  };
  const [showModal, setShowModal] = useState(false);
  const showLogoutModal = () => {
    setShowModal(true);
  };

  const handleCancelLogout = () => {
    setShowModal(false);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(setLogout(null));
    setShowModal(false);
    navigate("/signIn");
  };
  const logoutModal = (
    <Modal show={showModal} onHide={handleCancelLogout}>
      <Modal.Header closeButton>
        <Modal.Title>Logout Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to logout?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancelLogout}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleLogout}>
          Logout
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div className="text-white h-full min-h-screen flex flex-col">
      <Menu
        className="custom-menu text-white border-none"
        onClick={handleMenuItemClick}
        items={[
          {
            label: (
              <span className="flex items-center gap-2">
                <AppstoreOutlined />
                <span className="hidden md:block">Dashboard</span>
              </span>
            ),
            key: "/dashboard",
          },
          {
            label: (
              <span className="flex items-center gap-2">
                <ShoppingCart />
                <span className="hidden md:block">Orders</span>
              </span>
            ),
            key: "/orders",
          },
          {
            label: (
              <span className="flex items-center gap-2">
                <ClockCounterClockwise />
                <span className="hidden md:block">Order History</span>
              </span>
            ),
            key: "/orderhistory",
          },
          {
            label: (
              <span className="flex items-center gap-2">
                <ChatCircleDots />
                <span className="hidden md:block">Reviews</span>
              </span>
            ),
            key: "/reviews",
          },
          {
            label: (
              <span className="flex items-center gap-2">
                <CookingPot />
                <span className="hidden md:block">Menus</span>
              </span>
            ),
            key: "/menus",
          },
          {
            label: (
              <span className="flex items-center gap-2">
                <SettingOutlined />
                <span className="hidden md:block">Settings</span>
              </span>
            ),
            key: "/settings",
          },
          {
            label: (
              <span
                className="flex items-center gap-2 cursor-pointer text-red-400"
                onClick={showLogoutModal}
              >
                <LogoutOutlined />
                <span className="hidden md:block">Logout</span>
              </span>
            ),
          },
        ]}
      />
      {logoutModal}
    </div>
  );
}

export default Sidebar;
